import React, { useEffect, useState } from "react";

import { CategoryTabs, GameList } from "./components";

import parseUrl from "parse-url";
import { useNavigate } from "react-router-dom";
import { CategoryBar, SearchBar } from "../../components";
import { useLanguage } from "../../context/LangContext";
import localStorageKey from "../../enumerations/localStorageKey";
import styles from "./style.module.scss";
import "./style.scss";

const Home = () => {
  const [activeKey, setActiveKey] = useState("hot");
  const navigate = useNavigate();
  const language = useLanguage();
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [searchValue, setSearchValue] = useState("");

  const handleSignIn = () => {
    const query = parseUrl(window.location.href).query;
    const _lang = query?.lang;
    const _token = query?.token;
    if (_lang) {
      window.localStorage.setItem(localStorageKey.DEFAULT_LANG, _lang);
      console.log(_lang);
      language.changeLanguage(_lang);
    }

    if (_token) {
      window.localStorage.setItem(localStorageKey.TOKEN, _token);
      window.localStorage.setItem(localStorageKey.DEFAULT_CURRENCY, query.currency.toLowerCase());
      window.location.href = `${window.location.origin}`;
    }
  };

  useEffect(() => {
    console.debug("SEARCH VAL", searchValue);
    if (searchValue !== "") {
      // setSelectedCategory(-1);
    }
  }, [searchValue]);

  useEffect(() => {
    handleSignIn();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.topArea}>
        <CategoryTabs activeKey={activeKey} setActiveKey={setActiveKey} />
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
        <CategoryBar
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </div>
      <div className={styles.bottomArea}>
        <GameList
          selectedCategory={selectedCategory}
          nowActiveKey={activeKey}
          searchName={searchValue}
        />
      </div>
    </div>
  );
};
export default Home;
