import React from "react";
import { AuthContextProvider } from "./AuthContext";
import { LangContextProvider } from "./LangContext";
import { LoadingContextProvider } from "./LoadingContext";

const ContextList = [
  { name: "AuthInfo", context: AuthContextProvider },
  { name: "Lang", context: LangContextProvider },
  { name: "LoadingProvider", context: LoadingContextProvider },
];

function Context(props) {
  let output = props.children;
  ContextList.forEach((contextInfo) => {
    output = <contextInfo.context>{output}</contextInfo.context>;
  });
  return output;
}

export { AuthContextProvider, LangContextProvider };

export default Context;
