import { DotLoading } from "antd-mobile";
import React from "react";

import styles from "./style.module.scss";

const LoadingView = () => {
  return (
    <div className={styles.container}>
      <DotLoading color="var(--adm-color-primary)" />
    </div>
  );
};

export default LoadingView;
