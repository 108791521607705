import React from "react";
import { CustomNavBar } from "../../../components";
import CenterGroup from "./components/CenterGroup";
import RightGroup from "./components/RightGroup";
const Navigation = (params) => {
  const onBackPress = () => {
    params.goBack(params?.left.backURL);
  };

  const renderRight = <RightGroup {...(params?.right ?? {})} />;

  return (
    // <div />
    <CustomNavBar onBack={onBackPress} right={renderRight} backArrow={params?.renderBackButton}>
      <CenterGroup {...(params?.center ?? {})} />
    </CustomNavBar>
  );
};

export default Navigation;
