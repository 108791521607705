import "antd-mobile/bundle/style.css";
import "antd/dist/antd.min.css";
import { useEffect } from "react";
import "./App.css";
import { PRODUCTION_NAME_DEV, PRODUCTION_NAME_DISPLAY } from "./config";
import Context from "./context";
import "./plugins/i18n";
import RouterRender from "./router";

import { SnackbarProvider } from "notistack";

const currentTheme = require(`./theme/${PRODUCTION_NAME_DEV}/theme.json`);
const defaultTheme = require(`./theme/defaultTheme.json`);
const finalTheme = { ...defaultTheme, ...currentTheme };

function getFaviconEl() {
  return document.getElementById("favicon");
}
function getTitleEl() {
  return document.getElementById("head-title");
}
function App() {
  const setTheme = () => {
    const r = document.querySelector(":root");
    const final = Object.entries(finalTheme);
    final.map((color) => {
      r.style.setProperty(color[0], color[1]);
    });
  };
  useEffect(() => {
    setTheme();
  }, [finalTheme]);

  useEffect(() => {
    const favicon = getFaviconEl();
    const titleEl = getTitleEl();
    // console.log(favicon);
    favicon.href = `/images/versions/${PRODUCTION_NAME_DEV}/favicon.ico`;
    titleEl.innerHTML = PRODUCTION_NAME_DISPLAY;

    setTimeout(() => {
      initStyle();
    }, 100);
  }, []);
  const initStyle = () => {
    let vh = window.innerHeight * 0.01;
    window.document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.document.documentElement.style.setProperty("background", `#f5f5f5`);
  };

  /*
   * SCRIPT TO DETECT WHICH HTML ELEMENT IS BEING SCROLLED
   */
  // window.addEventListener(
  //   "scroll",
  //   function (e) {
  //     var el = e.target;
  //     while (el && el !== document && !isScrollable(el)) {
  //       el = el.parent;
  //     }
  //     console.debug("Scrolled element: " + (el.className || "document"));
  //   },
  //   true
  // );

  // function isScrollable(el) {
  //   return el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;
  // }
  //
  return (
    <div
      id="app"
      className="app"
      style={{
        maxWidth: "450px",
        position: "relative",
        height: "100vh",
        margin: "0 auto",
      }}
    >
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Context>
          <RouterRender />
        </Context>
      </SnackbarProvider>
    </div>
  );
}

export default App;
