import { Tabs } from "antd-mobile";

import "./style.scss";

function CustomTabs({ tabItems = [], defaultActiveKey = "", onChange = () => {}, activeKey = "" }) {
  const tabsStyle = {
    "--adm-color-border": "transparent",
    "--title-font-size": "var(--fontSetting-middle)",
    "--active-title-color": "var(--color-tabs-active-text)",
    "--active-line-color": "var(--color-tabs-active-line)",
    "--active-line-height": "4px",
    "--active-line-border-radius": "16px",
    color: "var(--color-tabs-text)",
  };
  return (
    <div className={`mg-custom-tabs`}>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        onChange={onChange}
        activeKey={activeKey}
        style={tabsStyle}
      >
        {tabItems?.map((item) => {
          return <Tabs.Tab title={item.title} key={item.key} />;
        })}
      </Tabs>
    </div>
  );
}

export default CustomTabs;
