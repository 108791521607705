import { Skeleton } from "antd-mobile";
import React from "react";

import styles from "./style.module.scss";

function GameListSkeleton() {
  return (
    <div className={styles.skeletonContainer}>
      {[...Array(4)].map((item, index) => {
        return (
          <div className={styles.skeletonItem} key={"sk" + index}>
            <Skeleton
              animated
              style={{
                "--width": "calc(25vw - 14px)",
                "--height": "calc(25vw - 14px)",
                "--border-radius": "8px",
              }}
            />
            <Skeleton
              lineCount={2}
              animated
              style={{
                "--width": "calc(25vw - 14px)",
                "--height": "var(--fontSetting-small)",
                marginTop: 4,
              }}
            />
            <Skeleton
              lineCount={2}
              animated
              style={{
                "--width": "calc(25vw - 14px)",
                "--height": "var(--fontSetting-small)",
                marginTop: 4,
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default GameListSkeleton;
