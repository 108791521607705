import { MAIN_API, META_MAIN_API, META_USER_API, PROTOCOL } from "../config";
import { getCurrency } from "../plugins/utils.js";
import req from "./https.js";

const CURRENCY = getCurrency();

export const request = (method, path, data = null, needAuth = false) =>
  req(method, `${PROTOCOL}://${MAIN_API}${path}`, data, needAuth);

export const requestMetaUser = (method, path, data = null, needAuth = false) =>
  req(method, `${PROTOCOL}://${META_USER_API}${path}`, data, needAuth);

export const requestMetaMain = (method, path, data = null, needAuth = true) =>
  req(method, `${PROTOCOL}://${CURRENCY}${META_MAIN_API}${path}`, data, needAuth);
