import { Image, Input } from "antd-mobile";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserBalance } from "./components";
import SearchIcon from "./image/SearchIcon.png";
import styles from "./style.module.scss";

const CustomSearchBar = ({ setSearchValue = () => {} }) => {
  const { t } = useTranslation();
  const [localSearchValue, setLocalSearchValue] = useState("");

  const handleSearch = useCallback(
    _.debounce(
      (searchValue) => {
        setSearchValue(searchValue);
      },
      [1000],
      { leading: false },
    ),
    [],
  );

  const onSearchValueChange = (val) => {
    setLocalSearchValue(val);
    handleSearch(val);
  };

  return (
    <div className={styles.container}>
      <UserBalance />
      <div className={styles.searchContainer}>
        <Input
          className={styles.searchInput}
          placeholder={t("home.search.placeholder")}
          type={"search"}
          value={localSearchValue}
          onChange={onSearchValueChange}
        />
        <Image
          className={styles.searchIcon}
          src={SearchIcon}
          width={14}
          height={14}
          fit={"contain"}
        />
      </div>
    </div>
  );
};

export default CustomSearchBar;
