import { Modal } from "antd";
import { Button } from "antd-mobile";
import WarningIcon from "./icons/WarningIcon";
import styles from "./style.module.scss";
import "./style.scss";

function CustomModal({
  isHaveCancel = true,
  isModalVisible,
  setIsModalVisible,
  maskClosable = false,
  title = "",
  content = "",
  needIcon = false,
  iconRender = () => {
    return <WarningIcon />;
  },
  okBtnText = " ",
  cancelBtnText = " ",
  onOk = () => {
    setIsModalVisible(false);
  },
  onCancel = () => {
    setIsModalVisible(false);
  },
  onCloseModal = () => {},
}) {
  const handleCloseModal = () => {
    setIsModalVisible(false);
    onCloseModal();
  };

  const btnStyle = {
    "--border-width": "0px",
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
      width={265}
      centered
      closable={false}
      maskClosable={maskClosable}
      destroyOnClose
      className="custom-modal"
      bodyStyle={{ borderRadius: "8px", padding: "0", maxHeight: "60vh" }}
    >
      <div className={`${styles.customModal}`}>
        <div className={`${styles.contentBox}`}>
          {needIcon ? <div className={`${styles.iconBox}`}>{iconRender()}</div> : ""}
          <div className={`${styles.title}`}>{title}</div>
          <div className={`${styles.content}`}>
            {typeof content === "function" ? content() : content}
          </div>
        </div>
        <div className={`${styles.buttonBox} ${!isHaveCancel ? styles.fullWidth : ""}`}>
          {isHaveCancel ? (
            <Button
              className={`${styles.cancelBtn}`}
              onClick={onCancel}
              style={{
                ...btnStyle,
                "--background-color": "var(--color-cancelBtn-bg)",
                "--text-color": "var(--color-cancelBtn-text)",
              }}
            >
              {cancelBtnText}
            </Button>
          ) : null}

          <Button
            className={`${styles.okBtn} ${!isHaveCancel ? styles.fullWidth : ""}`}
            onClick={onOk}
            style={{
              ...btnStyle,
              "--background-color": "var(--color-okBtn-bg)",
              "--text-color": "var(--color-okBtn-text)",
            }}
          >
            {okBtnText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CustomModal;
