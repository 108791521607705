import { Image } from "antd-mobile";
import { useTranslation } from "react-i18next";

import NoDataImg from "./images/noDataImg.png";

import styles from "./style.module.scss";

function NoData({ style = {}, text = "normal.noData" }) {
  const { t } = useTranslation();
  return (
    <div className={styles.mgNoData} style={style}>
      <Image src={NoDataImg} height={180} fit={"contain"} />
      <div className={styles.text}>{t(text)}</div>
    </div>
  );
}

export default NoData;
