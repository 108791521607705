import { request } from "../../apis";
import { getCurrency } from "../../plugins/utils";

const CURRENCY = getCurrency();

export const apiGetGameList = ({ params, page }) =>
  request("get", `/${CURRENCY}/game`, { ...params, page }, true);

export const apiGetGameLoginUrl = (params) =>
  request("get", `/${CURRENCY}/game/login`, params, true);

