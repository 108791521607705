import { Toast } from "antd-mobile";

const ALLOWED_PATH = ["/signin", "/signup", "/forgotPassword", "/"];

export const isAllowedWithoutCurrency = (path) => {
  return ALLOWED_PATH.includes(path);
};
export const tip = (msg) => {
  Toast.show({ icon: "fail", content: msg });
};

export const toLogin = () => {
  window.location.href = "/signin";
};

export const to403Page = () => {
  window.location.pathname = "/403";
};
