import {
  DECIMAL_SEPARATOR,
  MAX_DECIMAL_POINT,
  MIN_DECIMAL_POINT,
  THOUSANDS_SEPARATOR,
} from "../config";
// const zeroNumber = (num) => {
//   if (num == 0) return "0";
//   let initZero = `0${DECIMAL_SEPARATOR ? DECIMAL_SEPARATOR : "."}`;
//   for (let index = 0; index < num; index++) {
//     initZero = initZero + "0";
//   }

//   return initZero;
// };

export const moneyFormat = (
  number,
  minDecimalPoint = MIN_DECIMAL_POINT,
  maxDecimalPoint = MAX_DECIMAL_POINT,
  thousandSeparator = THOUSANDS_SEPARATOR,
  decimalSeparator = DECIMAL_SEPARATOR,
) => {
  const float = parseFloat(isNaN(number) || number === null ? 0.0 : number); //check undefined and null
  const round = Math.round(float * Math.pow(10, 10)) / Math.pow(10, 10); //round down to 5 decimal point
  const regex = RegExp(`0{0,${maxDecimalPoint - minDecimalPoint}}$`, "g"); //build regex with parameters
  const format = round.toFixed(maxDecimalPoint).replace(regex, ""); //limit digit to 5 decimal point, and remove trailing zero until min limit
  const split = format.split("."); //separate decimal and the number

  const join =
    split[1] === ""
      ? split[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
      : [split[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator), split[1]].join(
          decimalSeparator,
        ); //format thousand separator
  return join;
};
