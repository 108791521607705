import { DotLoading, InfiniteScroll, Toast } from "antd-mobile";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomPopup, NoData } from "../../../../components";
import { useLoading } from "../../../../context/LoadingContext";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useGetGameList, useGetGameLoginLink } from "../../../../hooks/games/games.hook";
import { useGetUserInfo } from "../../../../hooks/user/user.hook";
import GameItem from "../GameItem";
import GameListSkeleton from "../GameListSkeleton";

import styles from "./style.module.scss";

function GameList({ nowActiveKey, selectedCategory, searchName }) {
  const { t } = useTranslation();
  const [favList, setFavList] = useState([]);
  const [selectedGame, setSelectedGame] = useState();
  const [showGame, setShowGame] = useState(false);
  const [gameLink, setGameLink] = useState();
  const { setIsLoading } = useLoading();

  const localFavGames = window.localStorage.getItem(localStorageKey.FAVORITE_GAMES);

  const getUserFavList = () => {
    if (!localFavGames) {
      return "";
    } else {
      const favList = JSON.parse(localFavGames);
      const userData = favList.find((item) => item.id === userInfo?.account_id);
      if (!userData) {
        return "";
      } else {
        return userData.gameList.join();
      }
    }
  };
  const { data: userInfo } = useGetUserInfo({});

  const {
    data: gameListData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isGameListLoading,
    isError: isGameListError,
    error: errorGameList,
  } = useGetGameList({
    params: {
      game_channel: selectedCategory,
      is_hot: nowActiveKey === "hot" ? 1 : "",
      page_size: 40,
      name: searchName,
      game_ids: nowActiveKey === "favorite" ? getUserFavList() : "",
    },
    options: {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < lastPage.last_page) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
    },
  });

  const {
    data: gameLoginLink,
    refetch: getGameLoginLink,
    isRefetching: isRefetchingGameLink,
    isFetching,
  } = useGetGameLoginLink(
    { content_code: selectedGame?.mg_plus_game_code },
    {
      enabled: false,
      onSuccess: (data) => {
        console.log("success");
        setGameLink(data?.url);
      },
      onError: (e) => {
        console.log("hi error");
        Toast.show({ icon: "fail", content: e });
        setGameLink(undefined);
        setIsLoading(false);
      },
    },
  );

  const findUserFavList = () => {
    if (!localFavGames) {
      window.localStorage.setItem(
        localStorageKey.FAVORITE_GAMES,
        JSON.stringify([{ id: userInfo?.account_id, gameList: [] }]),
      );
      return;
    } else {
      const favList = JSON.parse(localFavGames);
      const userData = favList.find((item) => item.id === userInfo?.account_id);
      if (!userData) {
        window.localStorage.setItem(
          localStorageKey.FAVORITE_GAMES,
          JSON.stringify([...favList, { id: userInfo?.account_id, gameList: [] }]),
        );
        return;
      } else {
        setFavList(userData.gameList);
      }
    }
  };

  const loadMore = async () => {
    if (isFetchingNextPage === false) {
      await fetchNextPage();
    }
  };

  const renderDrawerContent = () => {
    return (
      <div style={{ padding: 0, height: "100%" }}>
        <iframe
          style={{ width: "100%", height: "100%" }}
          seamless="seamless"
          frameBorder={0}
          src={gameLink}
          title={"game"}
        />
      </div>
    );
  };

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {hasMore ? (
          <>
            <DotLoading />
          </>
        ) : (
          <span>{t("mobile.infiniteScroll.bottom")}</span>
        )}
      </>
    );
  };

  useEffect(() => {
    //update user favorite games list
    if (!!userInfo) findUserFavList();
  }, [userInfo, nowActiveKey, gameListData]);

  useEffect(() => {
    console.log("gameListData", gameListData);
  }, [gameListData]);

  useEffect(() => {
    if (!!selectedGame) getGameLoginLink();
  }, [selectedGame]);

  useEffect(() => {
    console.log("gameLink", gameLink);
    if (!!gameLink) setShowGame(true);
  }, [gameLink]);

  useEffect(() => {
    console.log("isFetching", isFetching);
    setIsLoading(isFetching);
  }, [isFetching]);

  return (
    <div className={styles.gameListContainer}>
      {isGameListLoading ? (
        <GameListSkeleton />
      ) : nowActiveKey === "favorite" && getUserFavList() === "" ? (
        <NoData />
      ) : (
        <>
          <div className={styles.gameList}>
            {gameListData?.pages?.map((group, index) => {
              return (
                <Fragment key={index}>
                  {index === 0 && group.data.length <= 0 ? (
                    <NoData />
                  ) : (
                    group.data.map((item) => {
                      return (
                        <GameItem
                          key={item.id}
                          item={item}
                          favList={favList}
                          userId={userInfo?.account_id}
                          gameOnClick={() => {
                            console.log("game on click");
                            setSelectedGame(item);
                          }}
                        />
                      );
                    })
                  )}
                </Fragment>
              );
            })}
          </div>
          <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
            <InfiniteScrollContent hasMore={hasNextPage} />
          </InfiniteScroll>
        </>
      )}
      <CustomPopup
        visible={showGame}
        onClose={() => {
          setShowGame(false);
          setSelectedGame(undefined);
          setGameLink(undefined);
        }}
        renderContent={renderDrawerContent}
      />
    </div>
  );
}

export default GameList;
