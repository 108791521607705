import { Image } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";

import Logo from "./images/logo/logo.png";

import styles from "./style.module.scss";

function Signin() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <Image src={Logo} height={175} fit={"contain"} />
      </div>
      <div
        className={styles.textContainer}
        dangerouslySetInnerHTML={{ __html: t("normal.please.login.again") }}
      />
    </div>
  );
}

export default Signin;
