import { Image } from "antd-mobile";
import { useTranslation } from "react-i18next";

import Favorite from "../../icons/favorite.png";
import Hot from "../../icons/hot.png";
import Lobby from "../../icons/lobby.png";

import { CustomTabs } from "../../../../components";

function CategoryTabs({ activeKey, setActiveKey }) {
  const { t } = useTranslation();
  const titleRender = (icon, text) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Image src={icon} width={20} height={20} fit={"cover"} style={{ marginRight: "4px" }} />
        {text}
      </div>
    );
  };
  const tabItems = [
    {
      title: titleRender(Hot, t("home.categoryTabs.hot")),
      key: "hot",
    },
    {
      title: titleRender(Lobby, t("home.categoryTabs.lobby")),
      key: "lobby",
    },
    {
      title: titleRender(Favorite, t("home.categoryTabs.favorite")),
      key: "favorite",
    },
  ];
  return (
    <CustomTabs tabItems={tabItems} activeKey={activeKey} onChange={(key) => setActiveKey(key)} />
  );
}

export default CategoryTabs;
