import { Image } from "antd-mobile";
import { useEffect, useState } from "react";

import localStorageKey from "../../../../enumerations/localStorageKey";
import emptyStar from "../../icons/emptyStar.svg";
import filledStar from "../../icons/filledStar.svg";

import styles from "./style.module.scss";

function GameItem({ item, userId, favList, gameOnClick }) {
  const [isLike, setIsLike] = useState(false);

  const nowLang = window.localStorage.getItem(localStorageKey.DEFAULT_LANG).replace(/\"/gm, "");
  const addFavList = (gameId) => {
    const localFavGames = window.localStorage.getItem(localStorageKey.FAVORITE_GAMES);
    const favList = JSON.parse(localFavGames);
    if (!!userId) {
      //userFavList 最後要push進favList再set到localStorage
      let userFavList = { id: userId, gamList: [] };
      const userIndex = favList.findIndex((i) => i.id === userId);
      userFavList = favList[userIndex];
      favList.splice(userIndex, 1);
      const gameIndex = userFavList.gameList.findIndex((i) => i === gameId);
      if (gameIndex > -1) {
        userFavList.gameList.splice(gameIndex, 1);
        setIsLike(false);
      } else {
        userFavList.gameList.push(gameId);
        setIsLike(true);
      }
      favList.push(userFavList);
      window.localStorage.setItem(localStorageKey.FAVORITE_GAMES, JSON.stringify(favList));
    }
  };

  useEffect(() => {
    setIsLike(Boolean(favList.includes(item.id)));
  }, [favList]);

  return (
    <div className={styles.gameItem} onClick={gameOnClick}>
      <div className={styles.imgBox}>
        <Image
          src={item.url}
          width={"calc(25vw - 14px)"}
          height={"calc(25vw - 14px)"}
          fit="cover"
          style={{ borderRadius: 8 }}
        />
        <div
          className={styles.starBox}
          onClick={(e) => {
            e.stopPropagation();
            addFavList(item.id);
            setIsLike(!isLike);
          }}
        >
          <Image src={isLike ? filledStar : emptyStar} />
        </div>
      </div>
      <div className={styles.titleBox}>
        <span>{nowLang === "zh" ? item.chinese_game_name : item.game_name}</span>
      </div>
    </div>
  );
}

export default GameItem;
