
const MAIN_API = "gamemgapi.1bbet.com";
const META_USER_API = "globaluser.1bbet.com";
const META_MAIN_API = "api.1bbet.com";
const MOBILE_URL = "https://gamemgslot.1bbet.com";
const PROTOCOL = "https";
const DECIMAL_SEPARATOR = '.';
const DEFAULT_CURRENCY = 'INR';
const DEFAULT_LANG = 'en';
const FIXED_NUMBER = 2;
const MAX_DECIMAL_POINT = 2;
const MIN_DECIMAL_POINT = 2;
const PRODUCTION_NAME_DEV = '1bbetv3';
const PRODUCTION_NAME_DISPLAY = '1BBET';
const THOUSANDS_SEPARATOR = ',';

 export {
MAIN_API,
META_USER_API,
META_MAIN_API,
MOBILE_URL,
PROTOCOL,
DECIMAL_SEPARATOR,
DEFAULT_CURRENCY,
DEFAULT_LANG,
FIXED_NUMBER,
MAX_DECIMAL_POINT,
MIN_DECIMAL_POINT,
PRODUCTION_NAME_DEV,
PRODUCTION_NAME_DISPLAY,
THOUSANDS_SEPARATOR,
}