import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import { apiGetGameList, apiGetGameLoginUrl } from "./service";

export const useGetGameList = ({
  params,
  options
}) => {
  Object.keys(params)?.map((item) => {
    if (params[item] === "all" || params[item] === "") delete params[item]
  })
  // console.log('hook params', params);
  return useInfiniteQuery(
    [queryKey.GAME_LIST, params.game_channel, params.name, params.game_ids, params.is_hot],
    ({ pageParam = 1 }) => apiGetGameList({ params, page: pageParam }).then((res) => {
      return res.data.data;
    }),
    {
      ...options,
    }
  );
};

export const useGetGameLoginLink = (params, options) => {
  return useQuery(
    [queryKey.GAME_LOGIN_LINK],
    () =>
      apiGetGameLoginUrl(params).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    }
  );
};