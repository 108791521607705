import { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import layouts from "./layouts";
import RouterList from "./router";
import RouterContext from "./RouterContext";

const findRouteConfig = (currentPath = "") => {
  let target = RouterList.find((route) => {
    if (route.path === "/") {
      return route.path === currentPath ?? window.location.pathname;
    }
    const regex = new RegExp(`^${route.path.replace(/:[^\/]*/g, ".*")}$`);
    return regex.test(currentPath ?? window.location.pathname);
  });
  if (!target) return "default";

  return !!target ? target : {}; //background
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const RouterRender = ({ router, match, showTransform }) => {
  let { location } = router;
  const { changeLayout, changeNavType } = useContext(RouterContext);

  useEffect(() => {
    changeLayout(findRouteConfig(location.pathname)?.layout);
    changeNavType(findRouteConfig(location.pathname)?.navType);
    // console.log(findRouteConfig(location.pathname)?.navType);
  }, [location]);

  return (
    <TransitionGroup>
      <CSSTransition classNames={"fade"} in={match != null} timeout={0} key={location?.key} appear>
        <Routes>
          {RouterList.map((route, index) => {
            return (
              <Route key={index} path={route.path} element={<route.component {...route.props} />} />
            );
          })}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

const RouterRenderWithRouter = withRouter(RouterRender);

const ProcessRouter = () => {
  const [layout, setLayout] = useState(findRouteConfig()?.layout ?? "default");
  const [navType, setNavType] = useState("");
  const actions = {
    changeLayout: (layout = "default") => {
      setLayout(layout ?? "default");
    },
    changeNavType: (navType = {}) => {
      setNavType(navType ?? {});
    },
  };

  var LayoutComponet = layouts[layout];

  return (
    <RouterContext.Provider value={actions}>
      <Router>
        <LayoutComponet navType={navType ? navType : { type: "default" }}>
          <RouterRenderWithRouter />
        </LayoutComponet>
      </Router>
    </RouterContext.Provider>
  );
};

export default ProcessRouter;
