import { useNavigate } from "react-router-dom";
import Navigation from "./components/Navigation";
import styles from "./style.module.scss";

const Layout = (props) => {
  let navigate = useNavigate();
  const { navType } = props;
  // console.log(navType);
  const renderHeader = () => {
    if (navType.hideHeader) {
      return null;
    }
    return (
      <Navigation
        {...navType}
        goBack={(backURL) => {
          navigate(backURL, {
            replace: true,
          });
        }}
      />
    );
  };

  return (
    <div className={styles.main}>
      {renderHeader()}
      <div className={styles.container}>{props.children}</div>
    </div>
  );
};
export default Layout;
