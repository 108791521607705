import { Tag } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

const CATEGORIES = [
  {
    value: "all",
    label: "normal.all",
  },
  {
    value: "Slots",
    label: "home.category.Slots",
  },
  {
    value: "QiPai",
    label: "home.category.QiPai",
  },
  {
    value: "TableGames",
    label: "home.category.TableGames",
  },
  {
    value: "Fishing",
    label: "home.category.Fishing",
  },
  {
    value: "VideoBingo",
    label: "home.category.VideoBingo",
  },
  {
    value: "LiveDealer",
    label: "home.category.LiveDealer",
  },
  {
    value: "Arcade",
    label: "home.category.Arcade",
  },
];

const CategoryBar = ({ selectedCategory = "all", setSelectedCategory = () => {} }) => {
  const { t } = useTranslation();

  const onCategorySelect = (category) => {
    setSelectedCategory(category.value);
  };

  const renderCategory = (category) => {
    return (
      <Tag
        onClick={() => onCategorySelect(category)}
        className={`${styles.tag} ${selectedCategory === category.value ? styles.selected : ""}`}
        key={category.value}
      >
        {t(`${category.label}`)}
      </Tag>
    );
  };

  return (
    <div className={styles.container}>
      {CATEGORIES.map((category) => {
        return renderCategory(category);
      })}
    </div>
  );
};

export default CategoryBar;
