import { SyncOutlined } from "@ant-design/icons";
import { Image } from "antd-mobile";
import { useGetUserInfo, useGetUserWallet } from "../../../../hooks/user/user.hook";
import { moneyFormat } from "../../../../plugins/numberFormat";
import Coin from "./images/coins.png";
import styles from "./style.module.scss";

const UserBalance = () => {
  const { data: userInfo } = useGetUserInfo({});
  const { data: userWallet, isLoading, isError, refetch, isFetching } = useGetUserWallet({});
  // const [userWallet, setUserWallet] = useState({
  //   money: 195042.3,
  // });

  const onRefreshBalance = () => {
    refetch();
  };

  return (
    <div className={styles.container}>
      <Image className={styles.image} src={Coin} width={24} height={24} fit={"contain"} />
      <div className={`${styles.moneyGroup}`}>
        <span className={`${styles.money}`}>
          {userWallet?.money ? moneyFormat(userWallet.money, 2, 2).split(".")[0] : "0"}.
        </span>
        <span className={`${styles.moneyFraction}`}>
          {userWallet?.money ? moneyFormat(userWallet.money, 2, 2).split(".")[1] : "00"}
        </span>
      </div>
      <div onClick={onRefreshBalance} className={styles.refreshContainer}>
        <SyncOutlined className={styles.refresh} spin={isFetching} />
      </div>
    </div>
  );
};

export default UserBalance;
